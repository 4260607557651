import request from '@/utils/request'
// 新增关键词
export function addKeyword(data) {
    return request({
        url: '/api/app/seokeywordconfig/addSeoConfig',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'post',
        data
    })
}
// 关键词列表
export function getKeyword(params) {
    return request({
        url: '/api/app/seokeywordconfig/seoConfigList',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'get',
        params
    })
}
// 关键词修改
export function editKeyword(data) {
    return request({
        url: '/api/app/seokeywordconfig/editSeoConfig',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'post',
        data
    })
}
// 关键词删除
export function delKeyword(data) {
    return request({
        url: '/api/app/seokeywordconfig/deleteSeoConfig',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'post',
        data
    })
}

// 抖音热词
export function getDouHotKey(params) {
    return request({
        url: '/api/app/seokeywordconfig/hotsearchSentences',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'get',
        params
    })
}
// 抖音热词
export function getDouHotKeyVideo(params) {
    return request({
        url: '/api/app/seokeywordconfig/getHotSearchVideo',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        method: 'get',
        params
    })
}
