<template>
    <div class="keyword">
        <div class="keyword_hd">
            <div class="select_box">
                <label class="label">标题</label>
                <el-input
                    v-model="keyword"
                    size="small"
                    placeholder="请输入内容"
                    style="width: 200px"
                    @input="searchKey"
                >
                </el-input>
                <el-button
                    type="primary"
                    size="small"
                    class="search_btn"
                    @click="keyword_dialog = true"
                    >添加</el-button
                >
            </div>
        </div>
        <div class="table_box">
            <el-table
                :data="list"
                size="small"
                height="100%"
                highlight-current-row
                style="width: 100%"
                v-loading="btn_load"
            >
                <el-table-column prop="configName" label="标题">
                </el-table-column>
                <el-table-column prop="subjectTerm" label="关键词">
                </el-table-column>
                <el-table-column prop="prefix" label="前缀"> </el-table-column>
                <el-table-column prop="suffix" label="后缀"> </el-table-column>
                <el-table-column prop="createTime" label="创建时间">
                    <template slot-scope="scope">
                        {{
                            moment(scope.row.createTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateTime" label="最后修改">
                    <template slot-scope="scope">
                        {{
                            moment(scope.row.updateTime).format(
                                "YYYY-MM-DD HH:mm:ss"
                            )
                        }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button
                            icon="el-icon-edit"
                            circle
                            @click="editKey(scope.$index, scope.row)"
                            type="text"
                            size="small"
                            title="修改"
                        >
                        </el-button>
                        <el-button
                            icon="el-icon-delete"
                            circle
                            title="删除"
                            @click="delKey(scope.$index, scope.row)"
                            type="text"
                            size="small"
                        >
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page_box">
            <el-pagination
                @size-change="page_size"
                @current-change="page_current"
                :current-page="current"
                :page-sizes="[10, 50, 100, 200, 300, 400]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
        <el-dialog
            title="关键词管理"
            :visible.sync="keyword_dialog"
            width="600px"
            top="50px"
            :before-close="close_config"
        >
            <div class="config">
                <div class="config_body">
                    <div class="card_box">
                        <div class="card_hd">标题</div>
                        <div class="card-body">
                            <el-input
                                v-model="title"
                                placeholder="请输入标题"
                            ></el-input>
                        </div>
                    </div>
                    <div class="card_box">
                        <div class="card_hd">关键词</div>
                        <div class="card-body">
                            <el-input
                                type="textarea"
                                placeholder="请输入关键词，每个词请用 , 号隔开，如 “关键词1,关键词2”"
                                v-model="subjectTerm"
                                maxlength="500"
                                show-word-limit
                                resize="none"
                                rows="4"
                            >
                            </el-input>
                        </div>
                        <div class="card_foo">
                            每个词请用 , 号隔开，如 “关键词1,关键词2”
                        </div>
                    </div>
                    <div class="card_box">
                        <div class="card_hd">前缀</div>
                        <div class="card-body">
                            <el-input
                                type="textarea"
                                placeholder="请输入前缀，每个词请用 , 号隔开，如 “前缀1,前缀2”"
                                v-model="prefix"
                                maxlength="500"
                                show-word-limit
                                resize="none"
                                rows="4"
                            >
                            </el-input>
                        </div>
                        <div class="card_foo">
                            每个词请用英文 , 号隔开，如 “前缀1,前缀2”
                        </div>
                    </div>
                    <div class="card_box">
                        <div class="card_hd">后缀</div>
                        <div class="card-body">
                            <el-input
                                type="textarea"
                                placeholder="请输入后缀词，每个词请用 , 号隔开，如 “后缀词1,后缀词2”"
                                v-model="suffix"
                                maxlength="500"
                                show-word-limit
                                resize="none"
                                rows="4"
                            >
                            </el-input>
                        </div>
                        <div class="card_foo">
                            每个词请用英文 , 号隔开，如 “后缀词1,后缀词2”
                        </div>
                    </div>
                </div>
                <div slot="footer" class="config_btns">
                    <el-button @click="keyword_dialog = false">取 消</el-button>
                    <el-button
                        type="primary"
                        @click="addKey"
                        :loading="btn_load"
                    >
                        <span v-if="id == ''">添 加</span>
                        <span v-else>修 改</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from "moment";
import { addKeyword, getKeyword, editKeyword, delKeyword } from "@/api/keyword";
export default {
    data() {
        return {
            // dialog
            // 标题
            btn_load: false,
            title: "",
            id: "",
            // 主词
            subjectTerm: "",
            // 前缀
            prefix: "",
            // 后缀
            suffix: "",
            // 头部
            keyword_dialog: false,
            keyword: "", //查询
            // table
            showDel: false,
            list: [],
            // page
            current: 1,
            size: 10,
            total: 0,
        };
    },
    created() {
        this.getKeyList();
    },
    methods: {
        moment,
        check() {},
        page_size() {},
        page_current() {},
        searchKey() {
            let json = {
                current: 1,
                size: 10,
                configName: this.keyword,
            };
            this.getKeyList(json);
        },
        async getKeyList(data) {
            let where;
            if (data) {
                where = {
                    current: this.current,
                    size: 10,
                    configName: this.keyword,
                };
            } else {
                where = data;
            }

            this.btn_load = true;
            let res = await getKeyword(where);
            if (res.code == 200) {
                this.list = res.data.records;
                this.total = res.data.total;
            } else {
                this.$message.error("获取列表失败！");
            }
            this.btn_load = false;
        },
        async addKey() {
            // 添加关键词
            if (!this.title) {
                return this.$message.error("请输入标题");
            }
            if (!this.subjectTerm) {
                return this.$message.error("请输入关键词");
            }
            if (!this.prefix && !this.suffix) {
                return this.$message.error("前缀与后缀最少填一个");
            }
            let json = {
                configName: this.title,
                subjectTerm: this.subjectTerm,
                prefix: this.prefix,
                suffix: this.suffix,
            };
            this.btn_load = true;
            var res;
            if (this.id) {
                // 修改
                json.id = this.id;
                res = await editKeyword(json);
                if (res.code != 200) {
                    return this.$message.error("修改失败！");
                } else {
                    let index = this.list.findIndex(
                        (item) => item.id == this.id
                    );

                    this.$set(this.list, index, json);
                    this.keyword_dialog = false;
                }
            } else {
                // 新增
                res = await addKeyword(json);
                if (res.code != 200) {
                    return this.$message.error("修改失败！");
                } else {
                    let index = this.list.findIndex(
                        (item) => item.id == res.data.id
                    );
                    this.list.unshift(res.data);
                    this.keyword_dialog = false;
                }
            }
            this.btn_load = false;
        },
        editKey(index, item) {
            this.title = item.configName;
            this.subjectTerm = item.subjectTerm;
            this.suffix = item.suffix;
            this.prefix = item.prefix;
            this.id = item.id;
            this.keyword_dialog = true;
        },
        delKey(index, item) {
            this.$confirm("是否删除当前关键词?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                console.log(item);
                let ids = [];
                ids.push(item.id);
                let json = {
                    seoKeywordConfigIds: ids.toString(),
                };
                let res = await delKeyword(json);
                if (res.code == 200) {
                    this.list.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                } else {
                    this.$message.error("删除失败！");
                }
            });
        },
        close_config() {
            this.keyword_dialog = false;
            this.title = "";
            this.subjectTerm = "";
            this.prefix = "";
            this.suffix = "";
            this.id = "";
        },
    },
};
</script>


<style lang="less" scoped>
.card_box {
    margin-bottom: 10px;
    .card_hd {
        line-height: 40px;
        font-weight: bold;
    }
    .card_foo {
        line-height: 34px;
        font-size: 12px;
        color: #999;
    }
}
.keyword {
    height: 100%;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .keyword_hd {
        height: 60px;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
        padding: 0 20px;
    }
    .table_box {
        flex: 1;
    }
    .page_box {
        height: 60px;
        display: flex;
        align-items: center;
    }
}
.select_box {
    display: flex;
    align-items: center;
}
.check_btn {
    position: relative;
    top: 2px;
}
.search_btn {
    margin-left: 10px;
}
.label {
    margin-right: 8px;
}
.config {
    height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .config_body {
        flex: 1;
        height: 0;
        overflow: auto;
        padding: 0 8px;
    }
    .config_btns {
        padding: 10px;
        display: flex;
        justify-content: right;
    }
}
</style>